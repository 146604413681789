
@media screen and (max-width: 768px) {
    
    .weeb {
        background-color: none;
        left: 0;
        right: 0;
        /* width: 100%; */
        margin-bottom: 2em;
    }

    #kaylahPic, #ajPic, #me {
        width: 85vw;
        /* right: 1em; */
        margin-bottom: 10vh;
    }

    .weebDescription {
        padding-left: 10%;
        margin-bottom: 2em;
        color: white;
        text-shadow:
            0.05em 0 black,
            0 0.05em black,
            -0.05em 0 black,
            0 -0.05em black,
            -0.05em -0.05em black,
            -0.05em 0.05em black,
            0.05em -0.05em black,
            0.05em 0.05em black;
        margin-top: 2em;
    }

    .weeb-container{
        display: flex;
        flex-direction: column;
        background: none;
    }

}

@media screen and (min-width: 769px) {
    #kaylahPic, #ajPic, #me {
        margin-bottom: 3em;
        height: 90vh;
    }

    #kaylahPic {
        margin-top: 7em;
    }

    .weebDescription {
        margin-bottom: 1em;
        color: white;
        text-shadow:
            0.05em 0 black,
            0 0.05em black,
            -0.05em 0 black,
            0 -0.05em black,
            -0.05em -0.05em black,
            -0.05em 0.05em black,
            0.05em -0.05em black,
            0.05em 0.05em black;
        margin-top: 10em;
        font-size: x-large;
        width: 85vw;
        margin-left: 10%;
    }
}