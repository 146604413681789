/* .logoHeader {
    position: absolute;
    width: 75%;
    top: 30%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
} */

/* @media screen and (max-width: 480px) {

    .logoHeader {
        position: absolute;
        width: 75%;
        top: 30%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
    }
    
} */

@media screen and (max-width: 768px) {

    .logoHeader {
        position: absolute;
        width: 60%;
        top: 30%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
    }
    
}

/* @media screen and (max-width: 1024px) {

    .logoHeader {
        position: absolute;
        width: 60%;
        top: 30%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
    }
    
} */

/* 
@media screen and (min-width: 1200px) {

    .logoHeader {
        position: absolute;
        width: 20%;
        top: 30%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
    }
    
} */

#age-clearance {
    position: absolute;
    /* top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%); */
    /* background-image: url("../assets/pink.png"); */
    top: 0; right: 0; bottom: 0; left: 0;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
}

#no {
    height: 200px;
    filter: drop-shadow(1px 1px 2px #000);
}

#yes {
    height: 200px;
    filter: drop-shadow(1px 1px 2px #000);
    /* background-image: url('../assets/yes.png');
    background-repeat: no-repeat; */
    display: inline-block;
}

.choices {
    height: 200px;
    position: absolute;
    width: 500px;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

#yes .inside {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}

#yes:hover .inside {
    display: inline;
}

#yes:active .inside {
    display: inline;
}

/* body {
    background-image: url("../assets/pink.png");
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
} */

h2 {
    color: azure;
    text-shadow: black 2px 2px;
}