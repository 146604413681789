@media screen and (max-width: 768px) {

    #logo {
        width: 70%;
        right: 10%;
        position: absolute;
        padding-top: 1em;
        filter: drop-shadow(1px 1px 5px white);
    }

    #mobileLogo {
        height: 60vh;
        position: absolute;
        filter: drop-shadow(1px 1px 5px white);
        left: 30%;
    }

    .beer {
        background-color: beige;
        left: 0;
        right: 0;
        height: 500px;
        margin-left: 1em;
        margin-right: 1em;
        margin-bottom: 10em;
        /* margin-top: 10em; */
        padding: 2em;
        position: relative;
    }

    p {
        font-size: larger;
        color: white;
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        /* box-shadow: black; */
        /* filter: drop-shadow(1px 1px 1px 1px black); */
        text-shadow:
            0.05em 0 black,
            0 0.05em black,
            -0.05em 0 black,
            0 -0.05em black,
            -0.05em -0.05em black,
            -0.05em 0.05em black,
            0.05em -0.05em black,
            0.05em 0.05em black;
    }

    #beerDescription {
        position: absolute;
        right: 20%;
        bottom: 20%;
        right: 5%;
        margin-left: 10%;
    }

    /* #hoppai {
        background-image: url('./assets/hoppaiWrap.png');
        background-size: contain;
    } */

    /* #hoppai img {
        display: none;
    } */

    #noticeMead {
        background-image: url('./assets/noticeMead.png');
        background-size: contain;
    }

    #noticeMead img {
        display: none;
    }
 
    #kimochiLogo {
        display: none;
    }
 
}
/* ----------- END MAX-WIDTH 768PX ---------------  */



@media screen and (min-width: 769px) {

    #logo {
        width: 30%;
        right: 10%;
        position: absolute;
        padding-top: 1em;
        filter: drop-shadow(1px 1px 5px white);
    }

    #mobileLogo {
        display: none;
    }

    .beer {
        background-color: beige;
        left: 0;
        right: 0;
        height: 500px;
        margin-left: 7em;
        margin-right: 4em;
        margin-bottom: 10em;
        margin-top: 10em;
        padding: 2em;
        position: relative;
    }

    p {
        font-size: larger;
        color: white;
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        /* box-shadow: black; */
        /* filter: drop-shadow(1px 1px 1px 1px black); */
        text-shadow:
            0.05em 0 black,
            0 0.05em black,
            -0.05em 0 black,
            0 -0.05em black,
            -0.05em -0.05em black,
            -0.05em 0.05em black,
            0.05em -0.05em black,
            0.05em 0.05em black;
    }

    #beerDescription {
        position: absolute;
        right: 20%;
        bottom: 20%;
        right: 10%;
        margin-left: 10%;
    }

    /* #hoppai {
        background-image: url('./assets/hoppaiWrap.png');
        background-size: contain;
        background-repeat: no-repeat;
        background: none;
    } */

    #kimochiLogo {
        width: 30%;
        right: 15%;
        position: absolute;
        padding-top: 4em;
        filter: drop-shadow(1px 1px 5px white);
    }

    #tsundereLogo {
        width: 30%;
        right: 10%;
        top: 10%;
        position: absolute;
        padding-top: 1em;
        filter: drop-shadow(1px 1px 5px white);
    }

    #tsundereDescription {
        position: absolute;
        right: 20%;
        bottom: 20%;
        right: 10%;
        left: 60%;
    }
    
    #noticeMead {
        /* background-image: url('./assets/noticeMead.png'); */
        /* background-size: contain; */
        background: none;
    }

    #noticeMead img {
        width: 80vw;
        left: 0;
        right: 0;
        position: absolute;
    }

    /* #hoppai img{
        width: 85vw;
        right: 5%;
    } */

} 
/* --------- END MIN-WIDTH 769PX ---------------- */


/* universal */
#bottle {
    height: 500px;
    position: absolute;
    float: left;
    left: 15%;
    filter: drop-shadow(1px 1px 5px #000);
    transition: transform .2s;
}

#bottle:hover {
    transform: scale(1.5);
}


#kouhai {
    background-image: url('./assets/kouhaiCollage.png');
    background-size: contain;
}

#hoppai {
    background-image: url('./assets/hoppaiBG.png');
    background-size: contain;
}

#kimochi {
    background-image: url('./assets/KimochiBG.png');
    background-size: contain;
}


#kimochiDescription {
    position: absolute;
    right: 10%;
    bottom: 40%;
    left: 70%;
}
/* end universalzs */



@media screen and (min-width: 1000px) {
    #noticeMead {
        padding-top: 5em;
        padding-bottom: 5em;
    }
    /* #hoppai img {
        height: 85vh;
        width: auto;
    } */
    /* #noticeMead img {
        height: 85vh;
        width: auto;
    } */

    #noticeMead img {
        width: 85vw;
        /* height: 85vh; */
        left: 0;
        right: 0;
        /* position: absolute; */
    }

    #kimochiLogo {
        height: 65vh;
        width: auto;
    }
}

@media screen and (min-width: 1920px) {
    #noticeMead img {
        height: 85vh;
    }
}