html, body {padding:0; margin:0; height:100%;}
/* footer{position: static; bottom: 0; left: 0; right: 0;} */


footer {
    /* position: fixed; */
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #e95646;
    color: white;
    text-align: center;
    font-size: small;
    z-index: 0;
    /* margin-top: 97.5vh; */
}

@media screen and (min-width: 768px) {
    
}