@media screen and (max-width: 768px) {
    
    .careers-container {
        margin-left: 10%;
        height: 100%;
    }

    .hireMe {
        background-color: beige;
        margin-left: 3%;
        margin-top: 5%;
        margin-right: 2%;
        padding: 1em;
    }

}


@media screen and (min-width: 769px) {

    .careers-container {
        display: flex;
        justify-content: center;
        justify-items: center;
        height: 97.5vh;
    }

    .hireMe {
        background-color: beige;
        margin-left: 3%;
        margin-top: 5%;
        margin-right: 2%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 1em;
    }

}


#timContacts {
    text-decoration: none!important;
    margin-top: 2em;
}