.form-container {
    display: flex;
    flex-direction: column;
    width: 70vw;
    padding-top: 5%;
    margin-left: 15%;
}

#full-name, #email, #message {
    background-color: beige;
}

.contactDescription {
    padding-top: 10%;
    color: white;
    text-shadow:
        0.05em 0 black,
        0 0.05em black,
        -0.05em 0 black,
        0 -0.05em black,
        -0.05em -0.05em black,
        -0.05em 0.05em black,
        0.05em -0.05em black,
        0.05em 0.05em black;
}

.iconContainer{
    /* padding-top: 5%; */
    width: 35vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30vw;
}

#senpai {
    text-decoration: none!important;
}

@media screen and (max-width: 768px) {
    .contactDescription {
        padding-left: 10%;
    }
}