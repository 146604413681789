.navMenu {
    position: relative;
  }
  
.navMenu button {
  position: fixed;
  left: 1%;
  top: 5%;
  z-index: 10;
  cursor: pointer;
  /* background: transparent; */
  border: none;
}

.menuNav {
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  top: 0;
  background: #e95646;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 0;
  overflow: hidden;
  max-width: 290px;
  z-index: 9;
}

.menuNav.showMenu {
  width: 100%;
}

.navItem {
  display: block;
  padding: 15px 40px;
  text-decoration: none;
  color: whitesmoke;
  text-transform: uppercase;
  font-weight: bold;
}

.menuNav li:first-child {
  margin-top: 7rem;
}

