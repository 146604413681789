@media screen and (max-width: 768px) {
    .logoHome {
        position: absolute;
        top: 3%;
        width: 35vw;
        left: 3rem;
        z-index: 3;
    }

    .pageHeader {
        position: sticky;
        top: 0;
        background-color: #e95646;
        color: azure;
        height: 85px;
        font-size: 3.5em;
        /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
        /* font-family: "Avenir Next Condensed Medium", Calibri, "Gill Sans", sans-serif; */
        font-family: Geneva, Tahoma, sans-serif;
        width: 100%;
        padding-top: .5em;
        /* z-index: 2; */
        text-transform: uppercase;
        padding-left: 3%;
    }

    .logoNav {
        position: absolute;
        width: 69%;
        display: flex;
        top: 8%;
        left: 20%;
    }

} /* END 768px */

@media screen and (min-width: 769px) {
    .logoHome {
        position: absolute;
        top: 3%;
        width: 50vw;
        left: 3rem;
        z-index: 3;
    }

    .pageHeader {
        position: absolute;
        top: 0;
        background-color: #e95646;
        color: azure;
        height: 100px;
        font-size: 4em;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        width: 100%;
    }

    .logoNav {
        position: absolute;
        width: 69%;
        display: flex;
        top: 8%;
        left: 20%;
    }

} /* END min 769  */